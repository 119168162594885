/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { createContext as e } from "./contextUtils.js";
let r;
function t() {
  return r || (r = n()), r;
}
class o {
  constructor() {
    this.available = !1, this.majorPerformanceCaveat = !1, this.maxTextureSize = 0, this.supportsVertexShaderSamplers = !1, this.supportsHighPrecisionFragment = !1, this.supportsColorBufferFloat = !1, this.supportsColorBufferFloatBlend = !1, this.supportsColorBufferHalfFloat = !1;
  }
}
function l(r) {
  if ("undefined" == typeof WebGL2RenderingContext) return null;
  const t = document.createElement("canvas");
  if (!t) return null;
  let o = e(t, {
    failIfMajorPerformanceCaveat: !0
  });
  if (null == o && (o = e(t), null != o && (r.majorPerformanceCaveat = !0)), null == o) return o;
  r.available = !0, r.maxTextureSize = o.getParameter(o.MAX_TEXTURE_SIZE), r.supportsVertexShaderSamplers = o.getParameter(o.MAX_VERTEX_TEXTURE_IMAGE_UNITS) > 0;
  const l = o.getShaderPrecisionFormat(o.FRAGMENT_SHADER, o.HIGH_FLOAT);
  return l && (r.supportsHighPrecisionFragment = l.precision > 0), o;
}
function n() {
  const e = new o(),
    r = l(e);
  return null == r || (e.supportsColorBufferFloat = null !== r.getExtension("EXT_color_buffer_float"), e.supportsColorBufferFloatBlend = null !== r.getExtension("EXT_float_blend"), e.supportsColorBufferHalfFloat = e.supportsColorBufferFloat || null !== r.getExtension("EXT_color_buffer_half_float")), e;
}
export { t as getWebGLCapabilities };